import React, { Component, CSSProperties } from 'react';
import './project-item.css';
import InteractiveIcon from '../interactive-icon/interactive-icon';
import ArrowDown from '../../images/arrow-down.png';
import ProjectItemDetails from '../project-item-details/project-item-details';
import CollapsibleContainer from '../collapsible-container/collapsible-container';
import { Project } from '../../data/projects';
import { getProjectId } from '../../utils/id';
const CollapsibleDetails = CollapsibleContainer(ProjectItemDetails);

export interface ProjectItemProps {
    project: Project;
}


export default class ProjectItem extends Component<ProjectItemProps, { collapsed: boolean }> {

    constructor(props: ProjectItemProps) {
        super(props);
        this.state = {
            collapsed: true,
        }
    }

    render() {
        const { project } = this.props;
        return (
            <div className={"project-item"} id={getProjectId(project)}>
                <div className={"project-item-header"}>
                    <div className={"project-item-image"} style={project.image !== "" ? { backgroundImage: `url(${project.image})` } : undefined} />
                    <div className="project-item-description" >
                        <article>
                            <h2 className="project-item-title">{project.title}</h2>
                            <h4 >{project.subTitle}</h4>
                            <p className="project-item-text">{project.description}</p>
                        </article>
                        <InteractiveIcon src={ArrowDown} onClick={this.toggleCollapseDetails} iconStyle={{ ...style.arrow, ...!this.state.collapsed ? style.arrowUp : undefined }} alt="Arrow to open collapsible"/>
                    </div>
                </div>
                <CollapsibleDetails collapsed={this.state.collapsed} project={project} />
            </div>
        )
    }

    toggleCollapseDetails = () => {
        this.setState({ collapsed: !this.state.collapsed });
    }
}

const style: { [key: string]: CSSProperties } = {
    arrow: {
        transition: "transform 0.3s",
        transform: "translateY(1px)",
    },
    arrowUp: {
        transform: "rotate(180deg) translateY(2px)",
    },
};