import React, { Component } from 'react';
import './info-item.css';
import InteractiveIcon from '../interactive-icon/interactive-icon';

interface InfoItemProps {
    icon: string;
    title: string;
    description?: string | JSX.Element | JSX.Element[] | (string | JSX.Element)[];
}


export default class InfoItem extends Component<InfoItemProps, {}> {

    constructor(props: InfoItemProps) {
        super(props);
    }

    render() {
        const { icon, title, description } = this.props;
        return (
            <div className="info-item" >
                <InteractiveIcon src={icon} bigIcon alt={title}/>
                <p>{title}</p>
                {description && <p>{description}</p>}
            </div>
        )
    }
}