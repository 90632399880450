import React, { Component } from 'react';
import './project-item-details.css';
import ImageCaption from '../image-caption/image-caption';
import { Project, Screen } from '../../data/projects';
import { convertTextToHtml } from '../../utils/text-parser';

export interface ProjectItemDetailsProps {
    project: Project;
}


export default class ProjectItemDetails extends Component<ProjectItemDetailsProps, {}> {

    constructor(props: ProjectItemDetailsProps) {
        super(props);
    }

    render() {
        const { project } = this.props;
        return (
            <div className="project-item-details" >
                <div className="details-flexbox" >
                    <div className="details-images">
                        {project.screens && this.renderImageCation(project.screens)}
                    </div>
                    <div className="details-text" >
                        <p>{convertTextToHtml(project.technicalInfo)}</p>
                    </div>
                </div>
            </div>
        )
    }

    renderImageCation(screens: Screen[]): Array<ImageCaption> {
        let items = new Array();
        for (let i = 0; i < screens.length; i++) {
            items.push(<ImageCaption key={screens[i].description + "_" + i} image={screens[i].image} caption={screens[i].description} />);
        }
        return items;
    }
}
