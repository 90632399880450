import React, { Component } from 'react';
import LayoutBuilder from '../components/layout-builder/layout-builder';
import MainHeader from '../components/main-header/main-header';
import ProjectsGallery from '../components/projects-gallery/projects-gallery';
import ProjectItem from '../components/project-item/project-item';
import ServicesSectoin from '../components/services-section/services-section';
import CertificatesSection from '../components/certificates-section/certificates-section';
import ContactSection from '../components/contact-section/contact-section';
import { Projects } from '../data/projects';


class Home extends Component<{}, {}> {

  constructor(p: {}) {
    super(p);
  }

  render() {
    return (
      <LayoutBuilder>
        <MainHeader />
        <ProjectsGallery />
        <div>
          {this.getProjectsItems()}
        </div>
        <ServicesSectoin />
        <CertificatesSection />
        <ContactSection />
      </LayoutBuilder>
    );
  }

  getProjectsItems() {
    let items = new Array();
    for (let i = 0; i < Projects.length; i++) {
      items.push(<ProjectItem key={"item_key_" + i} project={Projects[i]} />);
    }
    return items;
  }
}

export default Home;
