import React, { Component } from 'react';
import './services-section.css';
import InfoItem from '../../components/info-item/info-item';
import { Services } from '../../data/services';
import { convertTextToHtml } from '../../utils/text-parser';

export default class ServicesSectoin extends Component<{}, {}> {

    constructor(props: {}) {
        super(props);
    }

    render() {
        return (
            <div className="default-section-background services-section-background">
                <div className="default-section services-section" >
                    <h2>Dienst&shy;leistungen</h2>
                    <p className="services-header" >Wir entwickeln Mobile und Web Apps jeglicher Art. Mit React Native oder Native Android und iOS haben wir viele Projekte umgesetzt. Egal ob Navigator, Messenger wie WhatsApp oder Marketplace Apps wie Ebay-Kleinanzeigen, wir haben in nahezu allen Bereichen Erfahrung und können Ihr Projekt Erfolgreich umsetzen.</p>
                    <div className="services-list">
                        {this.getServices()}
                    </div>
                </div>
            </div >
        )
    }

    getServices(): Array<InfoItem> {
        let items = new Array();
        for (let i = 0; i < Services.length; i++) {
            items.push(<InfoItem key={Services[i].title + "_" + i}  {...Services[i]} description={Services[i].description ? convertTextToHtml(Services[i].description!) : undefined} />);
        }
        return items;
    }
}