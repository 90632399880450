import React, { Component, CSSProperties } from 'react';
import './main-header.css';
import ProfileSource from '../../images/profile.jpg';
import Button from '../button/button';
import InteractiveIcon from '../interactive-icon/interactive-icon';
import ArrowDownDouble from '../../images/arrow-down-double.png';

export default class MainHeader extends Component<{}, {}> {


    render() {
        return (
            <div id="top-header" className='main-header-container' >
                <div className='inner-container content-container'>
                    <div className='profile-container'  >
                        <img className='profile-image' src={ProfileSource} alt="Profilbild Artur Gräfenstein"/>
                    </div>
                    <div className='title-container' >
                        <h1>Mobile App Entwicklung</h1>
                        <h3>Artur Gräfenstein</h3>
                        <Button title="Mein Skillset" style={style.button} href="about#my-skills-section" />
                    </div>
                </div>
                <div className="down-arrow" >
                    <InteractiveIcon src={ArrowDownDouble} href="#home-portfolio-pager" alt="Arrow to scroll down" />
                </div>
            </div>
        );
    }
}

const style: { [key: string]: CSSProperties } = {
    button: {
        marginTop: 'var(--margin-medium)',
        marginBottom: 'var(--margin-big)',
    }
}
