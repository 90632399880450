import React, { Component } from 'react';
import './collapsible-container.css';
import WindowSizeListener from 'react-window-size-listener'

export interface CollapsibleContainerProps {
    collapsed: boolean;
}

const CollapsibleContainer = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
    return class extends Component<P & CollapsibleContainerProps, { collapsibleHeight: string }> {
        private divElement?: HTMLDivElement;
        constructor(props: P & CollapsibleContainerProps) {
            super(props);
            this.state = {
                collapsibleHeight: "0px",
            }
        }

        render() {
            const { collapsed } = this.props;
            return (
                <div className={"collapsible-container"} style={{ maxHeight: !collapsed ? this.state.collapsibleHeight : "0px" }} >
                    <WindowSizeListener onResize={this.resizeContainer} />
                    <div className="collapsible-innercontainer" ref={(divElement: HTMLDivElement) => this.divElement = divElement}>
                        <WrappedComponent {...this.props as P} />
                    </div>
                </div>
            )
        }

        componentDidMount() {
            this.setCollapsibleHeight();
        }

        setCollapsibleHeight() {
            this.divElement && this.setState({ collapsibleHeight: this.divElement.scrollHeight + "px" });
        }

        resizeContainer = () => {
            this.setCollapsibleHeight();
        }
    };
};

export default CollapsibleContainer;