import React, { Component } from 'react';
import './image-caption.css';

export interface ImageCaptionProps {
    image?: string;
    caption?: string;
}


export default class ImageCaption extends Component<ImageCaptionProps, {}> {

    constructor(props: ImageCaptionProps) {
        super(props);
    }

    render() {
        const { image, caption } = this.props;
        return (
            <div className="image-caption" >
                <div className="image-caption-image" style={{ backgroundImage: `url(${image})` }} ></div>
                <div className="image-caption-text" >
                    <p>{caption ? caption : "You can used in your office and the Text does longe as it should"}</p>
                </div>
            </div>
        )
    }

}
