import React, { Component } from 'react';
import './projects-gallery.css';
import GalleryItem from '../gallery-item/gallery-item';
import GalleryViewPager from '../gallery-view-pager/gallery-view-pager';
import { Projects } from '../../data/projects';


export default class ProjectsGallery extends Component<{}, {}> {

    constructor(props: {}) {
        super(props);
    }

    render() {
        return (
            <div className="project-gallery-section">
                <h2>Meine Projekte</h2>
                <p className="projects-header" >Eine Übersicht der Projekte, an denen ich gearbeitet habe.</p>
                <GalleryViewPager>
                    {this.getGalleryItems()}
                </GalleryViewPager>
            </div>
        )
    }

    getGalleryItems(): Array<GalleryItem> {
        let items = new Array();
        for (let i = 0; i < Projects.length; i++) {
            items.push(<GalleryItem key={Projects[i].title + "_" + i} project={Projects[i]} />);
        }
        return items;
    }
}