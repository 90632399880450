import MagnifyGlass from '../images/magnify-glass-icon.png';
import Design from '../images/design-icon.png';
import Api from '../images/api-icon.png';
import Phone from '../images/phone-icon.png';
import Web from '../images/web-icon.png';
import Quality from '../images/quality-icon.png';
import CloudServer from '../images/cloud-server-icon.png';
import Maintanance from '../images/maintanance-icon.png';

interface Service {
    icon: string;
    title: string;
    description?: string;
}

export const Services: Service[] = [
    {
        icon: MagnifyGlass,
        title: "Analyse & Kreation",
        description: "Wir analysieren Ihre Idee und erstellen mit Ihnen zusammen ein Manifest mit Potential.",
    },{
        icon: Design,
        title: "Konzeption & Design",
        description: "Bei der Konzeption und Erstellung des UI/UX Designs achten wir auf moderner Optik und sinnvolle Funktionen.",
    },{
        icon: Api,
        title: "API Entwicklung",
        description: `Logische Datenmodelle und eine erweiterbare Anwendungs\u00ADschnittstelle entwickeln wir optimiert für Ihr Geschäftsmodel.`,
    },{
        icon: Phone,
        title: "Mobile Entwicklung",
        description: "In einem agilen Prozess nehmen Design und Funktionen konkrete Form an.",
    },{
        icon: Web,
        title: "Web Entwicklung",
        description: "Dank zukunftsfähigen Technologien, entwickeln wir eine responsive und moderne Website, die mit allen Browsern kompatibel ist.",
    },{
        icon: Quality,
        title: "Qualitätsmanagement",
        description: "Während des gesamten Entwicklungszyklus führen wir automatisierte und manuelle Tests durch und halten die Funktionsfähigkeit auf allen Geräten im Blick.",
    },{
        icon: CloudServer,
        title: "Server & Hosting",
        description: "Modernste Server mit einer rund 24/7 Verfügbarkeit bieten wir in Maßgeschneiderten Paketen optimiert für Ihr Business an.",
    },{
        icon: Maintanance,
        title: "Betrieb & Wartung",
        description: "Über die Konzeption und Entwicklung hinaus sind wir Ihr Partner und veröffentlichen Ihre App über die App Stores und bieten Updates und Support.",
    }
]