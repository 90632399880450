import React, { Component } from 'react';
import './gallery-item.css';
import { Project } from '../../data/projects';
import { getProjectId } from '../../utils/id';

interface GalleryItemProps {
    project: Project
}
export default class GalleryItem extends Component<GalleryItemProps, {}> {


    render() {
        const { project } = this.props;
        return (
            <a className="gallery-item-top" onClick={() => this.scrollTo(getProjectId(project))}>
                <div className="gallery-item-container" >
                    <div className="gallery-item-image-container" >
                        <div className="absolute-fit" style={{ backgroundImage: `url(${project.image})` }} />
                    </div>
                    <div className="gallery-item-description">
                        <p className="gallery-item-text gallery-item-header" >{project.title}</p>
                        <p className="gallery-item-text gallery-item-sub-header">{project.subTitle}</p>
                    </div>
                </div>
            </a>
        )
    }

    scrollTo = (id: string) => {
        const element = document.getElementById(id);
        element && element.scrollIntoView(true);
    }
}