import React, { Component, CSSProperties } from 'react';
import './gallery-view-pager.css';
import { getWindowWidth } from '../../utils/window';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import WindowSizeListener from 'react-window-size-listener'
WindowSizeListener.DEBOUNCE_TIME = 10;

import InteractiveIcon from '../interactive-icon/interactive-icon';
import ArrowLeft from '../../images/arrow-left.png';
import ArrowRight from '../../images/arrow-right.png';

const TRANSITION_DURATION = 700;
const BORDER_PIECE_PERCENT = 0.4;

export enum SCREEN_SIZE {
    LARGE = Infinity,
    MEDIUM = 1300,
    SMALL = 800,
}

export default class GalleryViewPager extends Component<{}, { page: number, visibleItems: number, itemWidth: number, arrowTop: number, galleryKey:string }> {

    private mutationObserver?: MutationObserver;
    private clearIntervalTrasnsitionDuration?: NodeJS.Timeout;
    private transitionsInterval?: NodeJS.Timeout;
    private firstVisibleChild: number = 0;
    private lastVisibleChild: number = 0;

    constructor(props: {}) {
        super(props);
        let visibleItems = this.getItemsCountForWindowWidth();
        let itemWidth = this.getItemWidth(visibleItems);
        this.state = {
            page: 0,
            visibleItems,
            itemWidth,
            arrowTop: this.getArrowTop(itemWidth),
            galleryKey: "gallery_projects_key"
        };
    }

    render() {
        return (
            <div className="infinite-view-pager-container" id="home-portfolio-pager" key={this.state.galleryKey}>
                <WindowSizeListener onResize={this.resizeGallery} />
                <Carousel infinite centered keepDirectionWhenDragging draggable stopAutoPlayOnHover
                    value={this.state.page}
                    onChange={this.onChange}
                    itemWidth={this.state.itemWidth}
                    animationSpeed={TRANSITION_DURATION}
                    autoPlay={3000}
                >
                    {this.props.children}
                </Carousel>
                <div className="carousel-arrow left-arrow" onClick={() => this.onChange(this.state.page - 1)} style={{ top: this.state.arrowTop }} ><InteractiveIcon src={ArrowLeft} iconStyle={style.arrowLeft} pointer alt="go back in gallery" /></div>
                <div className="carousel-arrow right-arrow" onClick={() => this.onChange(this.state.page + 1)} style={{ top: this.state.arrowTop }}><InteractiveIcon src={ArrowRight} iconStyle={style.arrowRight} pointer alt="go further in gallery" /></div>
            </div>
        )
    }

    componentDidMount() {
        //recreate component after static render!
        this.setState({galleryKey: "gallery_projects_key_2"}, ()=>{
            if ("MutationObserver" in window) {
                this.mutationObserver = new MutationObserver(entries => {
                    let draggable: HTMLElement = entries && entries[0] && (entries[0].target as HTMLElement);
                    this.calculateTranslation(draggable);
                });
                var config = { attributes: true };
                let carusel = document.querySelector(".BrainhubCarousel__track--draggable");
                carusel && this.mutationObserver.observe(carusel, config);
            }
        })
    }

    calculateTranslation(draggable: HTMLElement) {
        let translation = draggable.style.transform;
        var findTranslation = new RegExp('\\((.*)px');
        if (!translation) return;
        var results = findTranslation.exec(translation);
        if (!results || !results[1]) return
        let currentTranslation = Math.abs(Number(results[1]));
        if (draggable.style.transitionDuration) {
            this.transitionsInterval && clearInterval(this.transitionsInterval);
            this.transitionsInterval = setInterval(() => {
                this.setTransparencyByTranslation(draggable, Math.abs((draggable.getBoundingClientRect() as DOMRect).x));
            }, 20);
            this.clearIntervalTrasnsitionDuration && clearTimeout(this.clearIntervalTrasnsitionDuration);
            this.clearIntervalTrasnsitionDuration = setTimeout(() => {
                this.transitionsInterval && clearInterval(this.transitionsInterval);
            }, TRANSITION_DURATION);
        } else {
            this.transitionsInterval && clearInterval(this.transitionsInterval);
            this.transitionsInterval = undefined;
            this.setTransparencyByTranslation(draggable, currentTranslation);
        }
    }

    setTransparencyByTranslation(draggable: HTMLElement, currentTranslation: number) {
        let leftBorder = currentTranslation / this.state.itemWidth;
        let firstElement = Math.floor(leftBorder);
        let rightBorder = (currentTranslation + (this.state.itemWidth * BORDER_PIECE_PERCENT)) / this.state.itemWidth;
        let lastElement = Math.floor(rightBorder);
        let itemsCount = Math.floor(this.state.visibleItems);
        let base: number = firstElement == lastElement ? itemsCount : itemsCount + 1;

        if (this.firstVisibleChild != firstElement || this.lastVisibleChild != firstElement + base) {
            this.firstVisibleChild = firstElement;
            this.lastVisibleChild = firstElement + base;
            for (let i = 1; i <= itemsCount; i++) {
                (draggable.children[firstElement + i] as HTMLElement).style.opacity = "1";
                (draggable.children[firstElement + i] as HTMLElement).children[0].children[0].classList.remove("disabled");
            }
            if (firstElement != lastElement) {
                (draggable.children[firstElement + itemsCount] as HTMLElement).style.opacity = "1";
            }
            (draggable.children[firstElement - 1] as HTMLElement).style.opacity = "1";
            (draggable.children[firstElement + base + 1] as HTMLElement).style.opacity = "1";
            (draggable.children[firstElement] as HTMLElement).children[0].children[0].classList.add("disabled");
            (draggable.children[firstElement + base] as HTMLElement).children[0].children[0].classList.add("disabled");
        }

        (draggable.children[firstElement] as HTMLElement).style.opacity = 1 - (leftBorder - firstElement) + "";
        (draggable.children[firstElement + base] as HTMLElement).style.opacity = (rightBorder - lastElement) + "";
    }

    transitionEnd = () => {
        let draggable = document.getElementsByClassName("BrainhubCarousel__track--draggable");
        if (draggable && draggable[0]) {
            this.calculateTranslation(draggable[0] as HTMLElement);
        }
    }

    componentWillUnmount() {
        this.mutationObserver && this.mutationObserver.disconnect();
    }

    onChange = (e: any) => {
        this.setState({ page: e.target ? e.target.value : e });
    }

    resizeGallery = () => {
        let visibleItems = this.getItemsCountForWindowWidth();
        let itemWidth = this.getItemWidth(visibleItems);
        this.setState({ visibleItems, itemWidth, arrowTop: this.getArrowTop(itemWidth) });
    }

    getArrowTop(itemWidth: number) {
        return 100 + 16 + (((itemWidth - 16) * 0.5625) / 2);
    }

    getItemWidth(itemsCount: number): number {
        return getWindowWidth() / itemsCount;
    }

    getItemsCountForWindowWidth() {
        var w = getWindowWidth();
        if (w < SCREEN_SIZE.SMALL) {
            return 1 + BORDER_PIECE_PERCENT;
        }
        if (w < SCREEN_SIZE.MEDIUM) {
            return 2 + BORDER_PIECE_PERCENT;
        }
        return 3 + BORDER_PIECE_PERCENT
    }
}

const style: { [key: string]: CSSProperties } = {
    arrowLeft: {
        transform: 'translateX(-3px)',
    },
    arrowRight: {
        transform: 'translateX(3px)',
    }
}